<template>
  <div>
    <Section1 :keyWord="'La mejor solución para préstamos con Asnef y nómina rápidos'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Préstamos con Asnef y nómina Rápidos</strong></h2>

      <h3 class="naranja"><strong>Préstamos con Asnef y nómina</strong></h3>
      <p class="">¿Te han rechazado un crédito o un préstamo por estar incluido en el fichero de impagados de la Asnef? Es un procedimiento muy habitual entre las entidades bancarias tradicionales.</p>
      <p class="">Salir de estos listados no es nada sencillo. Pero no tienes que preocuparte. Ya puedes conseguir <strong>préstamos con Asnef y nómina</strong> de manera muy sencilla, 100% online y en cuestión de minutos. ¿Quieres conocer todos los detalles?</p>

      <h3 class="naranja"><strong>¿Qué es la ASNEF?</strong></h3>
      <p class="">ASNEF son las siglas de la Asociación Nacional de Establecimientos Financieros de Crédito. Este organismo cuenta con un fichero en el que se incluye a todas las personas que tienen una deuda pendiente con alguna empresa.</p>
      <p class="">Las entidades bancarias, a la hora de conceder o rechazar un crédito, consultan estos ficheros. En caso de que la persona solicitante esté incluida en el listado, se limitan a rechazar el préstamo sin estudiar los motivos o la antigüedad de la deuda contraída.</p>
      <p class="">¿Qué puedes hacer como consumidor? Encontrar vías alternativas para poder acceder al crédito que necesitas. Los <strong>préstamos con Asnef y nómina</strong> son la solución más eficaz en estos casos.</p>

      <h3 class="naranja"><strong>¿En qué consisten los préstamos con Asnef y nómina?</strong></h3>
      <p class="">Al contrario de lo que ocurre con los bancos, las entidades que ofrecen <strong>préstamos con Asnef y nómina</strong> son conscientes de que las personas que aparecen en los ficheros de impagados pueden tener la solvencia suficiente para devolver su crédito sin ninguna dificultad.</p>
      <p class="">Una nómina laboral y el aval del coche es el único requisito que se solicita para obtener el dinero que necesitas. Otra de las ventajas del servicio de <strong>préstamos con Asnef y nómina</strong> es que la tramitación se formaliza de manera telemática.</p>
      <p class="">Un formulario de solicitud, una propuesta de crédito personalizada y la aportación de la documentación vía correo electrónico o por WhatsApp son suficientes para empezar a disfrutar de tu dinero en cuestión de horas.</p>

      <h3 class="naranja"><strong>¿Son más caros los intereses de los préstamos con Asnef y nómina?</strong></h3>
      <p class="">Las tasas de intereses asociadas a los <strong>préstamos con Asnef y nómina</strong> son los mismos en <router-link to="/">Ibancar</router-link>.</p>
      <p class="">La documentación aportada en el momento de la solicitud será estudiada por nuestros analistas para determinar cuál es el riesgo real de impago. Lo mejor de todo es que puedes recibir tu propuesta de manera totalmente gratuita y decidir si las condiciones se ajustan a tus necesidades.</p>

      <h3 class="naranja"><strong>¿Cuáles son los beneficios de los préstamos con Asnef y nómina?</strong></h3>
      <p class="">El principal beneficio de los <strong>préstamos con Asnef y nómina</strong> es que los solicitantes no tienen que preocuparse por estar incluidos en los ficheros de impagados de la Asnef.</p>
      <p class="">Esto ha permitido el acceso a créditos a miles de personas que nunca podrían haberlos conseguido a través de las entidades bancarias tradicionales. Pero esta no es la única ventaja de los <strong>préstamos con Asnef y nómina</strong>. Descubre todos sus beneficios:</p>

      <ul class="">
        <li>Tramitación 100% online</li>
        <li>Tu dinero en la cuenta en menos de 24 horas</li>
        <li>Requisitos y documentaciones mínimas</li>
        <li>Propuesta de crédito personalizada y totalmente gratuita</li>
        <li>Amortización de capital e intereses desde el primer día</li>
        <li>Misma cuota todos los meses. Pagarás siempre la misma cantidad</li>
        <li>Asesoramiento completo durante todo el proceso</li>
        <li>El coche solo es el aval del préstamo. No existe alquiler, el coche siempre será suyo</li>
      </ul>

    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamos-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo con ASNEF</h5>
                <span class="caret-sign">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                  </svg>
                </span>
              <p class="card-text pl-1 pt-2">Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamos con Asnef y nómina rápidos',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Consigue préstamo con Asnef y nómina rápidos ¿Necesitas conseguir dinero rápido con tu coche? Entra aquí, te explicamos con lujos y detalles todo lo que necesitas saber para adquirir una financiación.'},
        {name:'keywords', content:'prestamos con asnef y nomina rapidos'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

p.destacar {
    margin: 24px 0px;
    font-size: 1.2em;
    background-color: #FDF5E6;
    padding: 30px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>